.card {
  margin-top: 10px;

}

.form-inline label, label {
  margin-right: 5px;
  margin-left: 15px;
}


.title {
  font-weight: bold;
}

.close {
  position: relative;
  max-height: 20px;
  left: 10px;
}

.flex {
  display: flex;
}

.publication_date {
  margin-left: 10px;
}

.source_code, .publication_date {
  color: gray;
  opacity: 0.5;
}

img {
  height: 120px;
  width: 280px;
}

.image-mit-link, .count-articles {
  margin-left: auto;
}

.opacity-100 {
  opacity: 0;
}